import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Container from '../components/_layouts/Container'
import Grid from '../components/_layouts/Grid'
import GridItem from '../components/_layouts/GridItem'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  TextBody,
  TextBodyLarge,
  TextCaption,
} from '../components/TextStyles'
import Spacer from '../components/Spacer'
import Flow from '../components/Flow'
import ScrollSection from '../components/ScrollSection'

const IndexPage = () => (
  <>
    <SEO title="Style Guide" />
    <ScrollSection>
      <Container>
        <Grid>
          <GridItem tabletL={6} tabletLStart={5}>
            <Spacer axis="vertical" size={[120, 260]} />
            <Flow>
              <Heading1>Heading 1</Heading1>
              <Heading2>Heading 2</Heading2>
              <Heading3>Heading 3</Heading3>
              <Heading4>Heading 4</Heading4>
              <Heading5>Heading 5</Heading5>
              <TextBodyLarge>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
                error odio minima nemo dolor repellendus aliquam earum hic,
                ipsum magnam, vitae consequuntur, accusantium facere magni
                possimus fuga! Odio, a minima?
              </TextBodyLarge>
              <TextBody>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
                labore odit vitae odio voluptatem non reiciendis animi dolorum,
                cum minima corporis alias error assumenda. Dolorum repudiandae
                eius exercitationem. Iste, dolore.
              </TextBody>
              <TextCaption>Caption</TextCaption>
            </Flow>
            <Spacer axis="vertical" size={[120, 360]} />
          </GridItem>
        </Grid>
      </Container>
    </ScrollSection>
  </>
)

export default IndexPage

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
