import React from 'react'
import styled from 'styled-components'
import { TextStyle } from '../../styles/vars/textStyles.style'

const Heading1 = styled.p`
  ${TextStyle.Heading1}
  color: ${props => (props.color ? props.color : undefined)};
`

const Heading2 = styled.p`
  ${TextStyle.Heading2}
  color: ${props => (props.color ? props.color : undefined)};
`

const Heading3 = styled.p`
  ${TextStyle.Heading3}
  color: ${props => (props.color ? props.color : undefined)};
`

const Heading4 = styled.p`
  ${TextStyle.Heading4}
  color: ${props => (props.color ? props.color : undefined)};
`

const Heading5 = styled.p`
  ${TextStyle.Heading5}
  color: ${props => (props.color ? props.color : undefined)};
`

const TextBodyLarge = styled.p`
  ${TextStyle.TextBodyLarge}
  color: ${props => (props.color ? props.color : undefined)};
`

const TextBody = styled.p`
  ${TextStyle.TextBody}
  color: ${props => (props.color ? props.color : undefined)};
`

const TextBodySmall = styled.p`
  ${TextStyle.TextBodySmall}
  color: ${props => (props.color ? props.color : undefined)};
`

const TextCaption = styled.p`
  ${TextStyle.TextCaption}
  color: ${props => (props.color ? props.color : undefined)};

  ${props => (props.bold ? `font-weight:600; text-transform: uppercase;` : ``)}
`

const TextCTA = styled.p`
  ${TextStyle.TextCTA}
  color: ${props => (props.color ? props.color : undefined)};
`

const ThisHeading1 = ({ children, as, color }) => (
  <Heading1 as={as} color={color}>
    {children}
  </Heading1>
)

const ThisHeading2 = ({ children, as, color }) => (
  <Heading2 as={as} color={color}>
    {children}
  </Heading2>
)

const ThisHeading3 = ({ children, as, color }) => (
  <Heading3 as={as} color={color}>
    {children}
  </Heading3>
)

const ThisHeading4 = ({ children, as, color }) => (
  <Heading4 as={as} color={color}>
    {children}
  </Heading4>
)

const ThisHeading5 = ({ children, as, color }) => (
  <Heading5 as={as} color={color}>
    {children}
  </Heading5>
)

const ThisTextBodyLarge = ({ children, as, color }) => (
  <TextBodyLarge as={as} color={color}>
    {children}
  </TextBodyLarge>
)

const ThisTextBody = ({ children, as, color }) => (
  <TextBody as={as} color={color}>
    {children}
  </TextBody>
)

const ThisTextBodySmall = ({ children, as, color }) => (
  <TextBodySmall as={as} color={color}>
    {children}
  </TextBodySmall>
)

const ThisTextCaption = ({ children, as, color, bold }) => (
  <TextCaption as={as} color={color} bold={bold}>
    {children}
  </TextCaption>
)

const ThisTextCTA = ({ children, as, color }) => (
  <TextCTA as={as} color={color}>
    {children}
  </TextCTA>
)

export {
  ThisHeading1 as Heading1,
  ThisHeading2 as Heading2,
  ThisHeading3 as Heading3,
  ThisHeading4 as Heading4,
  ThisHeading5 as Heading5,
  ThisTextBodyLarge as TextBodyLarge,
  ThisTextBody as TextBody,
  ThisTextBodySmall as TextBodySmall,
  ThisTextCaption as TextCaption,
  ThisTextCTA as TextCTA,
}
