import { css } from 'styled-components'
import { font } from '../vars/font.style'
import { clamp } from '../utils/conversion.style'

const TextStyles = {
  heading1: {
    size: {
      min: 48,
      max: 638,
    },
    lineHeight: 1,
  },
  heading2: {
    size: {
      min: 36,
      max: 56,
    },
    lineHeight: 1,
  },
  heading3: {
    size: {
      min: 26,
      max: 42,
    },
    lineHeight: 1.16,
  },
  heading4: {
    size: {
      min: 22,
      max: 36,
    },
    lineHeight: 1.12,
  },
  heading5: {
    size: {
      min: 18,
      max: 28,
    },
    lineHeight: 1.14,
  },
  bodyLarge: {
    size: {
      min: 18,
      max: 22,
    },
    lineHeight: 1.47,
  },
  body: {
    size: {
      min: 16,
      max: 18,
    },
    lineHeight: 1.36,
  },
  bodySmall: {
    size: 16,
    lineHeight: 1.36,
  },
  caption: {
    size: {
      min: 16,
      max: 20,
    },
    lineHeight: 1.18,
  },
  cta: {
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.1,
  },
}

const Heading1 = css`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: ${TextStyles.heading1.lineHeight};
  text-transform: uppercase;
  letter-spacing: -0.03em;
  ${clamp(
    'font-size',
    TextStyles.heading1.size.min,
    TextStyles.heading1.size.max
  )}
`

const Heading2 = css`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: ${TextStyles.heading2.lineHeight};
  text-transform: uppercase;
  letter-spacing: -0.03em;
  ${clamp(
    'font-size',
    TextStyles.heading2.size.min,
    TextStyles.heading2.size.max
  )}
`

const Heading3 = css`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: ${TextStyles.heading3.lineHeight};
  text-transform: uppercase;
  letter-spacing: -0.03em;
  ${clamp(
    'font-size',
    TextStyles.heading3.size.min,
    TextStyles.heading3.size.max
  )}
`

const Heading4 = css`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: ${TextStyles.heading4.lineHeight};
  text-transform: uppercase;
  letter-spacing: -0.03em;
  ${clamp(
    'font-size',
    TextStyles.heading4.size.min,
    TextStyles.heading4.size.max
  )}
`

const Heading5 = css`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: ${TextStyles.heading5.lineHeight};
  text-transform: uppercase;
  letter-spacing: -0.03em;
  ${clamp(
    'font-size',
    TextStyles.heading5.size.min,
    TextStyles.heading5.size.max
  )}
`

const TextBodyLarge = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.bodyLarge.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.bodyLarge.size.min,
    TextStyles.bodyLarge.size.max
  )}
`

const TextBody = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.body.lineHeight};
  letter-spacing: -0.01em;
  ${clamp('font-size', TextStyles.body.size.min, TextStyles.body.size.max)}
`

const TextBodySmall = css`
  font-family: ${font.primary};
  font-size: ${TextStyles.bodySmall.size / 10}rem;
  font-weight: ${font.weight.regular};
  line-height: ${TextStyles.bodySmall.lineHeight};
  letter-spacing: -0.01em;
`

const TextCaption = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.bold};
  line-height: ${TextStyles.caption.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    TextStyles.caption.size.min,
    TextStyles.caption.size.max
  )}
`

const TextCTA = css`
  font-family: ${font.primary};
  font-weight: ${font.weight.bold};
  line-height: ${TextStyles.cta.lineHeight};
  letter-spacing: 0.01em;
  text-transform: uppercase;
  ${clamp('font-size', TextStyles.cta.size.min, TextStyles.cta.size.max)}
`

export const TextStyle = {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  TextBodyLarge,
  TextBody,
  TextBodySmall,
  TextCaption,
  TextCTA,
}
